import { type LegendConfig } from "../types";

export const getLegendConfig = (
  isCustomMode: boolean,
  fontColor: string,
  pageIconColor: string,
  widgetView: boolean
): LegendConfig | LegendConfig[] => {
  const commonLegendSettings: LegendConfig = {
    show: !widgetView,
    type: "scroll",
    icon: "circle",
    pageIconColor,
    textStyle: {
      color: fontColor,
    },
    pageTextStyle: {
      color: fontColor,
    },
  };

  const defaultLegend: LegendConfig = {
    ...commonLegendSettings,
    animation: true,
    bottom: true,
    width: 1000,
    pageIconSize: 17,
    itemGap: 35,
  };

  const extendedLegend: LegendConfig = {
    ...commonLegendSettings,
    animation: false,
    orient: "none", // workaround for multiline legend
    left: 30,
    bottom: 0,
    width: "100%",
    height: 50,
    itemGap: 20,
    pageIconSize: 15,
  };

  return isCustomMode
    ? defaultLegend
    : [
        {
          ...extendedLegend,
          bottom: 40,
          pageIconSize: 0,
          pageTextStyle: {
            color: "transparent",
          },
        },
        {
          ...extendedLegend,
          left: "auto",
          right: "50%",
          inactiveColor: "transparent",
          itemStyle: {
            color: "transparent",
          },
          textStyle: {
            color: "transparent",
          },
          selectedMode: false,
        },
      ];
};
