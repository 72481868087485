import { createContext, type ReactNode, useContext, useMemo } from "react";

import noop from "lodash/noop";

type ContextProps = {
  fetcher: (data: any) => Promise<Response>;
};

const defaultValue: ContextProps = {
  fetcher: noop as any,
};

const avaContext = createContext(defaultValue);

export const AvaContextProvider = ({
  children,
  fetcher,
}: {
  children: ReactNode;
  fetcher: (data: any) => Promise<Response>;
}) => {
  const value = useMemo(() => ({ fetcher }), [fetcher]);

  return <avaContext.Provider value={value}>{children}</avaContext.Provider>;
};
export const useAvaContext = () => useContext(avaContext);
