import { createContext, type ReactNode, useCallback, useContext, useRef } from "react";

import type { Message } from "./Message";

type AvaMessageHandlersContext = {
  registerHandler: (key: string, handler: (message: Message) => Promise<void>) => void;
  getHandler: (key: string) => (message: Message) => Promise<void>;
};

const avaMessageHandlers = createContext<AvaMessageHandlersContext | undefined>(undefined);

export const useAvaMessageHandlers = () => {
  const context = useContext(avaMessageHandlers);
  if (!context) {
    throw new Error("useAvaMessageHandlers must be used within a AvaMessageHandlersProvider");
  }

  return context;
};

export const AvaMessageHandlersProvider = ({ children }: { children: ReactNode }) => {
  const handlers = useRef<Record<string, (message: Message) => Promise<void>>>({});

  const registerHandler = useCallback((key: string, handler: (message: Message) => Promise<void>) => {
    handlers.current[key] = handler;
  }, []);

  const getHandler = useCallback((key: string) => handlers.current[key], []);

  return <avaMessageHandlers.Provider value={{ registerHandler, getHandler }}>{children}</avaMessageHandlers.Provider>;
};
